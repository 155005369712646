import { Routes } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import { authenticationGuard } from './shared/guards/authentication.guard';
import { UserRole } from './features/authentication/models/UserRole';

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./features/authentication/feature.routes'),
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./features/dashboard/feature.routes'),
        canActivate: [authenticationGuard],
      },
      {
        path: 'projects',
        loadChildren: () => import('./features/projects-grid/feature.routes'),
        canActivate: [authenticationGuard],
      },
      {
        path: 'create-project',
        loadChildren: () => import('./features/create-project/feature.routes'),
        canActivate: [authenticationGuard],
      },
      {
        path: 'project-management',
        loadChildren: () => import('./features/project-management/feature.routes'),
        canActivate: [authenticationGuard],
      },
      {
        path: 'admin',
        loadChildren: () => import('./features/admin/feature.routes'),
        canActivate: [authenticationGuard],
        data: { authorizedUserTypes: [UserRole.Administrator] },
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];
