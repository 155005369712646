<div class="notification-container"></div>
<cds-loading
 *ngIf="loaderActive"
 [isActive]="loaderActive"
 [overlay]="true">
</cds-loading>

<div [cdsTheme]="layoutTheme" class="container">
  <cds-header
    [brand]="header"
    name="Mobility Dashboard"
    skipTo="main-content-id"
    class="cds--header"
    [cdsTheme]="drawerTheme">
    <cds-header-global>
      <cds-header-action description="Logout"
        (selected)="logout()">
        <svg cdsIcon="logout" size="20"></svg>
      </cds-header-action>
    </cds-header-global>
  </cds-header>

  <cds-sidenav [expanded]="true" class="cds--sidenav neom-sidenav" [cdsTheme]="drawerTheme">
    <div *ngIf="user"
      class="user-info-container">
      <svg cdsIcon="user"
        class="user-icon"
        size="20">
      </svg>
      <div class="user-info">
        <span class="user-info-field">{{user.fullName | titlecase}}</span>
        <span class="user-info-field user-role">{{userRoleMap.get(user.userRole)}}</span>
      </div>
    </div>
    <ng-container *ngFor="let sideMenuItem of sideMenuItems(); trackBy: sideMenuTrackBy">
      <hr *ngIf="sideMenuItem.divider">
      <span class="neom-sidemenu-item-text">{{sideMenuItem.title}}</span>
      <cds-sidenav-item
        *ngFor="let menuItem of sideMenuItem.menuItems"
        [route]="menuItem.route"
        [routeExtras]="menuItem.routeExtras"
        [ngClass]="menuItem.additionalClass">
        <svg [cdsIcon]="menuItem.icon" size="16"></svg>
        {{ menuItem.title }}
      </cds-sidenav-item>
    </ng-container>
  </cds-sidenav>

  <main class="cds--content bx--content neom-main-content" id="main-content-id">
    <router-outlet />
  </main>
</div>

<ng-template #header>
  <img src="/assets/images/logo-neom.png" class="header-logo">
  <a class="cds--header__name" href="#">
    <span class="cds--header__name--prefix">NEOM&nbsp;
    </span>
    Mobility Dashboard
  </a>
</ng-template>
