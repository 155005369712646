import * as i0 from '@angular/core';
import { EventEmitter, Directive, Input, Output, HostBinding, HostListener, Component, ContentChildren, NgModule } from '@angular/core';
import { isFocusInFirstItem, isFocusInLastItem } from 'carbon-components-angular/common';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = (a0, a1, a2) => ({
  "cds--content-switcher--sm": a0,
  "cds--content-switcher--md": a1,
  "cds--content-switcher--lg": a2
});
const _c1 = ["*"];
class ContentSwitcherOption {
  constructor(renderer, hostElement) {
    this.renderer = renderer;
    this.hostElement = hostElement;
    /**
     * Internal name for the option.
     * Should be something that identifies the option to the application.
     * Accessible from the `ContentSwitcher` `selected` emitter
     */
    this.name = "option";
    /**
     * Emits when the option is selected.
     */
    this.selected = new EventEmitter();
    this.onClick = new EventEmitter();
    this.onFocus = new EventEmitter();
    this.switcherClass = "cds--content-switcher-btn";
    this.selectedClass = false;
    this.role = "tab";
    this.ariaSelected = false;
    this.tabindex = "-1";
    this._active = false;
  }
  /**
   * Used to activate the option. Only one option may be `active` at a time
   */
  set active(value) {
    this._active = value;
    this.selectedClass = value;
    this.ariaSelected = value;
    this.tabindex = value ? "0" : "-1";
  }
  get active() {
    return this._active;
  }
  hostClick(event) {
    this.onClick.emit(event);
    // skip setting and emitting if the option is already active
    if (this.active) {
      return;
    }
    this.active = true;
    this.selected.emit(true);
  }
  doFocus(event) {
    this.onFocus.emit(event);
    // skip setting and emitting if the option is already active
    if (this.active) {
      return;
    }
    this.active = true;
    this.selected.emit(true);
  }
  /*
  * encapsulating the content in a span with cds--content-switcher__label class
  * to mimic what is done in the react version
  */
  ngOnInit() {
    const hostNativeElement = this.hostElement.nativeElement;
    const spanWrapper = this.renderer.createElement("span");
    this.renderer.addClass(spanWrapper, "cds--content-switcher__label");
    const hostChildren = [];
    hostNativeElement.childNodes.forEach(node => hostChildren.push(node));
    hostChildren.forEach(node => {
      this.renderer.removeChild(hostNativeElement, node);
      this.renderer.appendChild(spanWrapper, node);
    });
    this.renderer.appendChild(hostNativeElement, spanWrapper);
  }
}
ContentSwitcherOption.ɵfac = function ContentSwitcherOption_Factory(t) {
  return new (t || ContentSwitcherOption)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
ContentSwitcherOption.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ContentSwitcherOption,
  selectors: [["", "cdsContentOption", ""], ["", "ibmContentOption", ""]],
  hostVars: 7,
  hostBindings: function ContentSwitcherOption_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function ContentSwitcherOption_click_HostBindingHandler($event) {
        return ctx.hostClick($event);
      })("focus", function ContentSwitcherOption_focus_HostBindingHandler($event) {
        return ctx.doFocus($event);
      });
    }
    if (rf & 2) {
      i0.ɵɵattribute("role", ctx.role)("aria-selected", ctx.ariaSelected)("tabIndex", ctx.tabindex);
      i0.ɵɵclassMap(ctx.switcherClass);
      i0.ɵɵclassProp("cds--content-switcher--selected", ctx.selectedClass);
    }
  },
  inputs: {
    active: "active",
    name: "name"
  },
  outputs: {
    selected: "selected",
    onClick: "onClick",
    onFocus: "onFocus"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContentSwitcherOption, [{
    type: Directive,
    args: [{
      selector: "[cdsContentOption], [ibmContentOption]"
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    active: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    selected: [{
      type: Output
    }],
    onClick: [{
      type: Output
    }],
    onFocus: [{
      type: Output
    }],
    switcherClass: [{
      type: HostBinding,
      args: ["class"]
    }],
    selectedClass: [{
      type: HostBinding,
      args: ["class.cds--content-switcher--selected"]
    }],
    role: [{
      type: HostBinding,
      args: ["attr.role"]
    }],
    ariaSelected: [{
      type: HostBinding,
      args: ["attr.aria-selected"]
    }],
    tabindex: [{
      type: HostBinding,
      args: ["attr.tabIndex"]
    }],
    hostClick: [{
      type: HostListener,
      args: ["click", ["$event"]]
    }],
    doFocus: [{
      type: HostListener,
      args: ["focus", ["$event"]]
    }]
  });
})();

/**
 * The content switcher can be used for toggling between distinct options.
 * Similar to tabs, but without an associated content panel.
 *
 * Get started with importing the module:
 *
 * ```typescript
 * import { ContentSwitcherModule } from 'carbon-components-angular';
 * ```
 *
 * ```html
 * <cds-content-switcher (selected)="selected($event)">
 *		<button cdsContentOption>First section</button>
 *		<button cdsContentOption>Second section</button>
 *		<button cdsContentOption>Third section</button>
 * </cds-content-switcher>
 * ```
 *
 * [See demo](../../?path=/story/components-content-switcher--basic)
 */
class ContentSwitcher {
  constructor(elementRef) {
    this.elementRef = elementRef;
    this.ariaLabel = "content switcher";
    /**
     * Set content switcher size
     */
    this.size = "md";
    /**
     * Emits the activated `ContentSwitcherOption`
     */
    this.selected = new EventEmitter();
  }
  ngAfterViewInit() {
    const firstActive = this.options.find(option => option.active);
    // delay setting active until the DOM has settled
    if (!firstActive) {
      setTimeout(() => this.options.first.active = true);
    }
    // subscribe to each item, emit when one is selected, and reset the active states
    this.options.forEach(option => {
      option.selected.subscribe(_ => {
        const active = option;
        this.options.forEach(option => {
          if (option !== active) {
            option.active = false;
          }
        });
        this.selected.emit(active);
      });
    });
  }
  hostkeys(event) {
    const buttonList = Array.from(this.elementRef.nativeElement.querySelectorAll("[cdsContentOption], [ibmContentOption]"));
    switch (event.key) {
      case "ArrowRight":
        event.preventDefault();
        if (!isFocusInLastItem(event, buttonList)) {
          const index = buttonList.findIndex(item => item === event.target);
          buttonList[index + 1].focus();
        } else {
          buttonList[0].focus();
        }
        break;
      case "ArrowLeft":
        event.preventDefault();
        if (!isFocusInFirstItem(event, buttonList)) {
          const index = buttonList.findIndex(item => item === event.target);
          buttonList[index - 1].focus();
        } else {
          buttonList[buttonList.length - 1].focus();
        }
        break;
      case "Home":
        event.preventDefault();
        buttonList[0].focus();
        break;
      case "End":
        event.preventDefault();
        buttonList[buttonList.length - 1].focus();
        break;
    }
  }
}
ContentSwitcher.ɵfac = function ContentSwitcher_Factory(t) {
  return new (t || ContentSwitcher)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
ContentSwitcher.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ContentSwitcher,
  selectors: [["cds-content-switcher"], ["ibm-content-switcher"]],
  contentQueries: function ContentSwitcher_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ContentSwitcherOption, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.options = _t);
    }
  },
  hostBindings: function ContentSwitcher_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("keydown", function ContentSwitcher_keydown_HostBindingHandler($event) {
        return ctx.hostkeys($event);
      });
    }
  },
  inputs: {
    ariaLabel: "ariaLabel",
    size: "size"
  },
  outputs: {
    selected: "selected"
  },
  ngContentSelectors: _c1,
  decls: 2,
  vars: 6,
  consts: [["role", "tablist", 1, "cds--content-switcher", 3, "ngClass"]],
  template: function ContentSwitcher_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵprojection(1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(2, _c0, ctx.size === "sm", ctx.size === "md", ctx.size === "lg"));
      i0.ɵɵattribute("aria-label", ctx.ariaLabel);
    }
  },
  dependencies: [i1.NgClass],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContentSwitcher, [{
    type: Component,
    args: [{
      selector: "cds-content-switcher, ibm-content-switcher",
      template: `
		<div
			[attr.aria-label]="ariaLabel"
			class="cds--content-switcher"
			[ngClass]="{
				'cds--content-switcher--sm': size === 'sm',
				'cds--content-switcher--md': size === 'md',
				'cds--content-switcher--lg': size === 'lg'
			}"
			role="tablist">
			<ng-content></ng-content>
		</div>
	`
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    ariaLabel: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    selected: [{
      type: Output
    }],
    options: [{
      type: ContentChildren,
      args: [ContentSwitcherOption]
    }],
    hostkeys: [{
      type: HostListener,
      args: ["keydown", ["$event"]]
    }]
  });
})();
class ContentSwitcherModule {}
ContentSwitcherModule.ɵfac = function ContentSwitcherModule_Factory(t) {
  return new (t || ContentSwitcherModule)();
};
ContentSwitcherModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ContentSwitcherModule
});
ContentSwitcherModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContentSwitcherModule, [{
    type: NgModule,
    args: [{
      declarations: [ContentSwitcher, ContentSwitcherOption],
      exports: [ContentSwitcher, ContentSwitcherOption],
      imports: [CommonModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ContentSwitcher, ContentSwitcherModule, ContentSwitcherOption };
