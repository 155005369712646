import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { RouterModule, provideRouter } from '@angular/router';
import { routes } from './app.routes';
import {
  ButtonModule,
  ContentSwitcherModule,
  Hamburger,
  Header,
  HeaderAction,
  HeaderGlobal,
  HeaderItem,
  HeaderMenu,
  HeaderModule,
  HeaderNavigation,
  I18nModule,
  IconModule,
  PanelModule,
  RadioModule,
  SideNavModule,
  SwitcherList,
  SwitcherListItem,
} from 'carbon-components-angular';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from './shared/interceptors/auth.interceptor';
import { HttpClientModule } from '@angular/common/http';
import { ApiService, GoogleMapsLoaderService } from './shared/services';
import { AuthService } from './features/authentication/services/auth.service';
import { MenuItemsSignal } from './shared/signals';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(
      BrowserModule,
      ButtonModule,
      RadioModule,
      IconModule,
      I18nModule,
      RouterModule,
      CommonModule,
      SideNavModule,
      ContentSwitcherModule,
      HeaderModule,
      PanelModule,
      HttpClientModule,
    ),
    Header,
    HeaderItem,
    HeaderMenu,
    HeaderNavigation,
    HeaderGlobal,
    HeaderAction,
    Hamburger,
    SwitcherList,
    SwitcherListItem,
    {
      provide: APP_INITIALIZER,
      useFactory: (googleMapsLoader: GoogleMapsLoaderService) => async () => await googleMapsLoader.loadGoogleMaps(),
      deps: [GoogleMapsLoaderService],
      multi: true,
    },
    provideHttpClient(withInterceptors([authInterceptor])),
    ApiService,
    AuthService,
    MenuItemsSignal,
  ],
};
